import { ReactNode } from 'react'

type IconButtonProps = {
  children: ReactNode
  onClick?: () => void
  className?: string
  disabled?: boolean
}

export const IconButton = ({
  children,
  onClick,
  className,
  disabled,
}: IconButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center w-[50px] h-[50px] rounded-full bg-gray-100 hover:bg-gray-200 transition duration-200 ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
