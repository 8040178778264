import { useMsal } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'

import { useAuthStore } from '../../store/auth'
import { Button } from '../Button'

export const LoginButton = () => {
  const { instance } = useMsal()
  const { login } = useAuthStore()
  const { t } = useTranslation()

  const handleLogin = async () => {
    await login(instance)
  }

  return <Button onClick={handleLogin}>{t('login')}</Button>
}
