import { useEffect, useRef, useState } from 'react'
import { Bell, CheckCircle, Clock, XCircle } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@/components/IconButton'
import { getNotifications } from '@/services/notifications'

export const Notifications = () => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [notifications, setNotifications] = useState<
    {
      id: number
      title: string
      message: string
      timestamp: string
      status: string
    }[]
  >([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const markAllAsRead = () => {
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, status: 'read' })),
    )
  }

  useEffect(() => {
    async function fetchNotifications() {
      setLoading(true)
      try {
        const response = await getNotifications()

        setNotifications(response.notifications || [])
      } catch (err) {
        setError('Failed to load notifications')
      } finally {
        setLoading(false)
      }
    }

    fetchNotifications()
  }, [])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen])

  const unreadCount = notifications.filter((n) => n.status === 'unread').length

  return (
    <div className="relative" ref={dropdownRef}>
      <IconButton
        onClick={toggleDropdown}
        className="relative flex items-center"
      >
        <Bell size={25} className="text-steel" />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 bg-blue text-white text-xs font-bold px-2 py-0.5 rounded-full">
            {unreadCount}
          </span>
        )}
      </IconButton>

      {isOpen && (
        <div className="absolute right-0 mt-3 w-72 bg-white shadow-lg rounded-lg p-3 border border-sky">
          <div className="flex justify-between items-center pb-2 border-b border-sky">
            <h3 className="text-lg font-semibold text-gray-800">
              {t('notifications.title')}
            </h3>
            <button
              onClick={markAllAsRead}
              className="text-blue-600 dark:text-blue-400 text-sm hover:underline"
            >
              {t('notifications.markAllAsRead')}
            </button>
          </div>

          <div className="mt-2 max-h-60 flex flex-col gap-2 overflow-y-auto">
            {loading ? (
              <p className="text-gray-500 text-sm text-center py-2">
                {t('notifications.loading')}
              </p>
            ) : error ? (
              <p className="text-red-500 text-sm text-center py-2">{error}</p>
            ) : notifications.length === 0 ? (
              <p className="text-gray-500 text-sm text-center py-2">
                {t('notifications.noNew')}
              </p>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`flex items-start gap-3 p-2 rounded-lg transition ${
                    notification.status === 'unread'
                      ? 'bg-gray-100'
                      : 'bg-transparent'
                  }`}
                >
                  {notification.status === 'unread' ? (
                    <Clock size={20} className="text-yellow-500" />
                  ) : (
                    <CheckCircle size={20} className="text-green-500" />
                  )}

                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-800">
                      {notification.title}
                    </p>
                    <p className="text-xs text-gray-600">
                      {notification.message}
                    </p>
                    <span className="text-xs text-gray-400">
                      {new Date(notification.timestamp).toLocaleString()}
                    </span>
                  </div>

                  {notification.status === 'unread' && (
                    <button
                      onClick={() =>
                        setNotifications((prev) =>
                          prev.map((n) =>
                            n.id === notification.id
                              ? { ...n, status: 'read' }
                              : n,
                          ),
                        )
                      }
                    >
                      <XCircle
                        size={18}
                        className="text-gray-400 hover:text-gray-600"
                      />
                    </button>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  )
}
