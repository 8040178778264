import React from 'react'
import { initializeIcons } from '@fluentui/react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { createHashRouter, RouterProvider } from 'react-router-dom'

import './index.css'
import '@xyflow/react/dist/style.css'

import { Shell } from './containers/shell'
import i18next from './languages/i18n/config'
import { Login } from './modules/Login'
// Import ThemeProvider

initializeIcons()

const router = createHashRouter([
  {
    path: '/',
    element: <Shell />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: 'home',
        lazy: () => import('./pages/home'),
      },
      {
        path: 'chat',
        lazy: () => import('./pages/chat'),
      },
      {
        path: 'acquisition',
        // lazy: () => import('./pages/acquisition/Chat'),
        lazy: () => import('./modules/Acquisition/pages/NewAcquisition'),
      },
      {
        path: 'contract',
        lazy: () => import('./pages/contract'),
      },
      {
        path: 'settings',
        lazy: () => import('./pages/settings'),
      },
      {
        path: '*',
        lazy: () => import('./pages/NoPage'),
      },
      {
        path: 'components',
        lazy: () => import('./pages/components'),
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>,
)
