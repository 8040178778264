import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import fedflowLogo from '../../assets/images/fedflow.svg'
import { LoginButton } from '../../components/LoginButton'
import { useAuthStore } from '../../store/auth'

export const Login = () => {
  const navigate = useNavigate()
  const { loggedIn } = useAuthStore()

  useEffect(() => {
    if (loggedIn) {
      navigate('/home', { replace: true })
    }
  }, [loggedIn])

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] bg-gray-lighter">
      <img src={fedflowLogo} alt="Fedflow Logo" className="w-52 mb-8" />
      <LoginButton />
    </div>
  )
}
