import { PropsWithChildren, ReactNode } from 'react'

type ButtonProps = PropsWithChildren & {
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost'
  size?: 'sm' | 'md' | 'lg'
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  rounded?: boolean
  onClick?: () => void
  className?: string
}

const baseStyles =
  'flex items-center justify-center font-medium transition duration-200'
const sizeStyles = {
  sm: 'px-3 py-1 text-sm',
  md: 'px-4 py-2 text-base',
  lg: 'px-5 py-3 text-lg',
}

const variantStyles = {
  primary: 'bg-blue text-white hover:bg-navy disabled:bg-gray-light',
  secondary: 'bg-gray-light text-dark hover:bg-gray-lighter disabled:bg-gray',
  outline:
    'border border-blue text-blue hover:bg-sky disabled:border-gray-light disabled:text-gray',
  ghost:
    'bg-transparent text-dark hover:bg-transparent disabled:text-gray-light',
}

const roundedStyles = {
  default: 'rounded-md',
  full: 'rounded-full',
}

export const Button = ({
  variant = 'primary',
  size = 'md',
  icon,
  iconPosition = 'left',
  disabled = false,
  rounded = false,
  onClick,
  children,
  className,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${
        rounded ? roundedStyles.full : roundedStyles.default
      } ${icon ? 'gap-2' : ''} disabled:cursor-not-allowed ${className}`}
      disabled={disabled}
    >
      {icon && iconPosition === 'left' && <span>{icon}</span>}
      {children}
      {icon && iconPosition === 'right' && <span>{icon}</span>}
    </button>
  )
}
