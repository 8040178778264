import { BACKEND_URI } from '../api'

export async function getNotifications(): Promise<Response> {
  const url = `${BACKEND_URI}/notifications`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
  const data = await response.json()

  return data
}
