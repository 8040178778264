type AvatarProps = {
  initials: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

const sizeClasses = {
  sm: 'size-6 text-xs',
  md: 'size-8 text-sm',
  lg: 'size-10 text-base',
  xl: 'size-12 text-lg',
  '2xl': 'size-14 text-xl',
}

export const Avatar = ({ initials, size = 'md' }: AvatarProps) => {
  return (
    <span
      className={`inline-flex items-center justify-center rounded-full bg-gray-500 ${sizeClasses[size]}`}
    >
      <span className="font-medium text-white">{initials}</span>
    </span>
  )
}
