import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import { AIChat } from '../../components/AIChat'
import { useAuthStore } from '../../store/auth'
import { Header } from './components/Header'
import { Sidebar } from './components/Sidebar'

const Layout = () => {
  const { loggedIn } = useAuthStore()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const allowAccess = loggedIn
  const showAiChat = false

  const pageTitles = {
    '/home': t('layout.homeTitle'),
    '/chat': t('layout.chatTitle'),
    '/acquisition': t('layout.acquisitionTitle'),
    '/contract': t('layout.contractTitle'),
    '/settings': t('layout.settings'),
  } as const

  const title =
    pageTitles[location.pathname as keyof typeof pageTitles] ||
    t('layout.homeTitle')

  return (
    <div className="flex h-screen bg-gray-100">
      {allowAccess && (
        <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      )}

      <div className="flex flex-col flex-1 transition-all duration-300">
        {allowAccess && <Header title={title} isSidebarOpen={isSidebarOpen} />}

        <main className="flex flex-1 flex-col pt-[120px] bg-gray-lighter px-6 h-[calc(100vh-100px)] overflow-auto">
          <Outlet />
        </main>
      </div>
      {showAiChat && <AIChat />}
    </div>
  )
}

export default Layout
