import { useState, useEffect, useRef } from 'react'
import { useMsal } from '@azure/msal-react'
import {
  Plus,
  SignOut,
  FileText,
  Globe,
  MagnifyingGlass,
  CaretRight,
  CaretDown,
  SidebarSimple,
} from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import fedflowLogoIcon from '../../../../assets/images/fedflow-icon.svg'
import fedflowLogo from '../../../../assets/images/fedflow.svg'
import { Button } from '../../../../components/Button'
import { useAuthStore } from '../../../../store/auth'

import { getContracts } from '@/services/contract'

export const Sidebar = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (state: boolean) => void
}) => {
  const { t } = useTranslation()
  const { instance } = useMsal()
  const { logout } = useAuthStore()
  const navigate = useNavigate()
  const sidebarRef = useRef<HTMLDivElement>(null)

  const [isExpanding, setIsExpanding] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [expandedMenus, setExpandedMenus] = useState<{
    [key: string]: boolean
  }>({
    contracts: false,
    requests: false,
    market: false,
  })
  const [contracts, setContracts] = useState<
    Array<{ id: number; name: string; date: string }>
  >([])
  const [isLoadingContracts, setIsLoadingContracts] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleLogoClick = () => {
    navigate('/home')
  }

  const handleNavigateToContract = (contractId: number) => {
    navigate(`/contract?id=${contractId}`)
  }

  useEffect(() => {
    async function fetchContracts() {
      try {
        setIsLoadingContracts(true)
        const data = await getContracts()
        setContracts(data.contracts)
      } catch (error) {
        console.error('Failed to fetch contracts:', error)
      } finally {
        setIsLoadingContracts(false)
      }
    }

    fetchContracts()
  }, [])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false)
      }
    }
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showDropdown])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
        setShowDropdown(false)
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  useEffect(() => {
    if (isExpanding) {
      const timeout = setTimeout(() => {
        setIsOpen(true)
        setIsExpanding(false)
      }, 300)
      return () => clearTimeout(timeout)
    }
  }, [isExpanding])

  const handleOpenSidebar = () => {
    if (!isOpen && !isExpanding) {
      setIsExpanding(true)
    }
  }

  const toggleMenu = (menu: string) => {
    setExpandedMenus((prev) => ({ ...prev, [menu]: !prev[menu] }))
  }

  const handleDropdownItemClick = (type: string) => {
    navigate(`/${type.toLowerCase()}`)
    setShowDropdown(false)
  }

  return (
    <aside
      ref={sidebarRef}
      className={`sticky h-screen bg-white shadow-lg z-50 flex flex-col transition-[width] duration-300 ${
        isExpanding || isOpen ? 'w-[249px]' : 'w-[72px]'
      }`}
      onClick={handleOpenSidebar}
    >
      <Button
        variant="ghost"
        icon={<SidebarSimple size={24} />}
        onClick={() => setIsOpen(false)}
        className="flex !justify-end mr-[6px] pt-4"
      />
      <div className="relative h-[80px] pt-5 flex justify-center">
        <img
          src={fedflowLogoIcon}
          alt="Logo Small"
          className={`absolute w-[33px] transition-all duration-700 ease-out cursor-pointer ${isOpen ? 'opacity-0 translate-x-full' : 'opacity-100 translate-x-0'}`}
          onClick={handleLogoClick}
        />

        <img
          src={fedflowLogo}
          alt="Logo Large"
          className={`absolute w-40 transition-all duration-700 ease-out cursor-pointer ${isOpen ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full'}`}
          onClick={handleLogoClick}
        />
      </div>

      <div
        className={`px-5 pt-5 flex flex-col gap-4 ${
          isOpen ? '' : 'items-center justify-center'
        }`}
      >
        <div className="relative w-full flex !justify-start">
          <Button
            variant="primary"
            icon={<Plus size={20} />}
            onClick={() => isOpen && setShowDropdown((prev) => !prev)}
            className={`${isOpen ? 'w-full py-3 whitespace-nowrap overflow-hidden text-ellipsis' : 'w-9'} transition-all duration-300 `}
          >
            {isOpen && t('sidebar.newWorkflow')}
          </Button>

          {showDropdown && isOpen && (
            <div
              ref={dropdownRef}
              className="absolute left-0 mt-14 w-full bg-white shadow-lg rounded-md py-2 z-50"
            >
              <DropdownItem
                label={t('sidebar.contract')}
                onClick={() => handleDropdownItemClick('contract')}
              />
              <DropdownItem
                label={t('sidebar.marketResearch')}
                onClick={() => handleDropdownItemClick('acquisition')}
              />
              <DropdownItem
                label={t('sidebar.communication')}
                onClick={() => handleDropdownItemClick('acquisition')}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={`relative mt-5 mx-4 flex items-center bg-gray-lighter rounded-full transition-all duration-700 ease-out ${isOpen ? 'px-4 py-3 ' : 'pl-2 py-2.5'}`}
      >
        <MagnifyingGlass size={20} className="text-steel cursor-pointer" />
        {isOpen && (
          <input
            type="text"
            placeholder={t('sidebar.searchPlaceholder')}
            className="bg-transparent outline-none ml-2 w-full text-steel-light placeholder:text-steel-light"
          />
        )}
      </div>

      {isOpen && (
        <>
          <nav className="mt-6 space-y-2 px-4 max-h-[calc(100vh-380px)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <SidebarMenu
              label={t('sidebar.contracts')}
              icon={<FileText size={20} />}
              expanded={expandedMenus.contracts}
              onClick={() => toggleMenu('contracts')}
              isLoading={isLoadingContracts}
              subItems={contracts}
              onSubItemClick={handleNavigateToContract}
            />

            {/* <SidebarMenu
              label={t('sidebar.requests')}
              icon={<Folder size={20} />}
              expanded={expandedMenus.requests}
              onClick={() => toggleMenu('requests')}
              // mock
              subItems={[
                t('sidebar.requestX'),
                t('sidebar.requestY'),
                t('sidebar.requestZ'),
              ]}
            /> */}

            <SidebarMenu
              label={t('sidebar.market')}
              icon={<Globe size={20} />}
              expanded={expandedMenus.market}
              onClick={() => toggleMenu('market')}
              // mock
              subItems={[
                { id: 1, name: t('sidebar.marketAnalysis'), date: '' },
                { id: 2, name: t('sidebar.trends'), date: '' },
                { id: 3, name: t('sidebar.opportunities'), date: '' },
              ]}
              onSubItemClick={(id) => navigate(`/market/${id}`)}
            />
          </nav>
        </>
      )}

      <div className="mt-auto border-t border-gray-light px-4 py-4">
        <Button
          variant="ghost"
          icon={<SignOut size={24} />}
          className={`${isOpen ? 'w-full py-3 !justify-start' : 'w-9'} font-normal h-12 transition-all duration-300 whitespace-nowrap overflow-hidden text-ellipsis`}
          onClick={() => logout(instance)}
        >
          {isOpen && t('sidebar.logOut')}
        </Button>
      </div>
    </aside>
  )
}

const SidebarMenu = ({
  label,
  icon,
  expanded,
  onClick,
  subItems,
  isLoading = false,
  onSubItemClick,
}: {
  label: string
  icon: React.ReactNode
  expanded: boolean
  onClick: () => void
  subItems: Array<{ id: number; name: string; date: string }> | string[]
  isLoading?: boolean
  onSubItemClick?: (id: number) => void
}) => {
  return (
    <>
      <div
        className="cursor-pointer flex items-center justify-between px-4 py-2 rounded-md text-black hover:text-blue hover:bg-sky"
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          {expanded ? (
            <CaretDown size={16} className="text-blue" />
          ) : (
            <CaretRight size={16} className="text-blue" />
          )}
          {icon}
          <span>{label}</span>
        </div>
      </div>

      {expanded && (
        <div className="pl-8 space-y-2">
          {isLoading ? (
            <div className="text-gray-400 px-4 py-2">Loading...</div>
          ) : (
            subItems.map((item, index) => {
              const itemName = typeof item === 'string' ? item : item.name
              const itemId = typeof item === 'string' ? index : item.id

              return (
                <SidebarSubItem
                  key={index}
                  label={itemName}
                  onClick={() => onSubItemClick && onSubItemClick(itemId)}
                />
              )
            })
          )}
        </div>
      )}
    </>
  )
}

const SidebarSubItem = ({
  label,
  onClick,
}: {
  label: string
  onClick?: () => void
}) => {
  return (
    <div
      className="text-black hover:text-blue px-4 py-2 rounded-md cursor-pointer"
      onClick={onClick}
    >
      {label}
    </div>
  )
}

const DropdownItem = ({
  label,
  onClick,
}: {
  label: string
  onClick?: () => void
}) => {
  return (
    <div
      className={`px-4 py-2 cursor-pointer hover:text-blue text-dark hover:bg-sky`}
      onClick={onClick}
    >
      {label}
    </div>
  )
}
