import { IPublicClientApplication } from '@azure/msal-browser'
import { create } from 'zustand'
import {
  checkLoggedIn,
  getRedirectUri,
  loginRequest,
} from '../../config/auth/authConfig'

interface AuthState {
  loggedIn: boolean
  login: (instance: IPublicClientApplication) => Promise<void>
  logout: (instance: IPublicClientApplication) => Promise<void>
  checkAuthStatus: (instance: IPublicClientApplication) => Promise<void>
}

export const useAuthStore = create<AuthState>((set) => ({
  loggedIn: false,
  token: null,
  login: async (instance) => {
    try {
      const loginResponse = await instance.loginPopup({
        ...loginRequest,
        redirectUri: getRedirectUri(),
      })

      if (loginResponse) {
        const isLoggedIn = await checkLoggedIn(instance)
        console.log(isLoggedIn)

        set({ loggedIn: true })
      }
    } catch (error) {
      console.error('Login failed:', error)
    }
  },
  logout: async (instance) => {
    try {
      await instance.logoutPopup()
      set({ loggedIn: false })
    } catch (error) {
      console.error('Logout failed:', error)
    }
  },
  checkAuthStatus: async (instance) => {
    try {
      const isLoggedIn = await checkLoggedIn(instance)
      if (isLoggedIn) {
        set({ loggedIn: true })
      }
    } catch (error) {
      console.error('Auth check failed:', error)
    }
  },
}))
