import { Gear } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '../../../../components/Avatar'
import { IconButton } from '../../../../components/IconButton'
import { useUserStore } from '../../../../store/user'
import { Notifications } from '../Notifications/Notifications'

export const Header = ({
  title,
  isSidebarOpen,
}: {
  title: string
  isSidebarOpen: boolean
}) => {
  const navigate = useNavigate()
  const user = useUserStore((state) => state.username)

  const getInitials = (name: string) => {
    const nameParts = name.split(' ')
    const initials = nameParts
      .map((part) => part.charAt(0))
      .join('')
      .toUpperCase()
    return initials.length > 2 ? initials.slice(0, 2) : initials
  }

  return (
    <header
      className={`fixed z-40 top-0 left-0 h-[100px] bg-white p-4 flex justify-between items-center border-b-[1.5px] border-softBlue w-full
        transition-all duration-300 ${isSidebarOpen ? 'pl-[279px]' : 'pl-[102px]'}
      `}
    >
      <h1 className="text-2xl font-semibold text-deepNavy">{title}</h1>
      <div className="flex items-center gap-8">
        <IconButton onClick={() => navigate('/settings')}>
          <Gear size={25} className="text-steel" />
        </IconButton>
        <Notifications />
        <Avatar initials={getInitials(user || 'User')} size="xl" />
      </div>
    </header>
  )
}
