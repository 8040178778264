import { useLogin, isUsingAppServicesLogin } from '../../config/auth/authConfig'
import {
  ChatAppResponse,
  ChatAppResponseOrError,
  ChatAppRequest,
  Config,
  SimpleAPIResponse,
  ChatAppRequestOverrides,
  Feedback,
  ContractCreationRequest,
  ContractCreationResponse,
  ContractFillerRequest,
  ContractFillerResponse,
} from './models'

export const BACKEND_URI = ''

export async function getHeaders(
  idToken: string | undefined,
): Promise<Record<string, string>> {
  // If using login and not using app services, add the id token of the logged in account as the authorization
  if (useLogin && !isUsingAppServicesLogin) {
    if (idToken) {
      return { Authorization: `Bearer ${idToken}` }
    }
  }

  return {}
}

export async function configApi(): Promise<Config> {
  const response = await fetch(`${BACKEND_URI}/config`, {
    method: 'GET',
  })

  return (await response.json()) as Config
}

export async function askApi(
  request: ChatAppRequest,
  idToken: string | undefined,
): Promise<ChatAppResponse> {
  const headers = await getHeaders(idToken)
  const response = await fetch(`${BACKEND_URI}/ask`, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })

  if (response.status > 299 || !response.ok) {
    throw Error(`Request failed with status ${response.status}`)
  }
  const parsedResponse: ChatAppResponseOrError = await response.json()
  if (parsedResponse.error) {
    throw Error(parsedResponse.error)
  }

  return parsedResponse as ChatAppResponse
}

export async function persistSettings(
  request: ChatAppRequestOverrides,
  idToken: string | undefined,
): Promise<Response> {
  const url = `${BACKEND_URI}/settings`
  const headers = await getHeaders(idToken)
  return await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
}

export async function submitFeedback(
  request: Feedback,
  idToken: string | undefined,
): Promise<Response> {
  const url = `${BACKEND_URI}/feedback`
  const headers = await getHeaders(idToken)
  return await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
}

export async function generateContract(
  request: ContractCreationRequest,
  idToken: string | undefined,
): Promise<ContractCreationResponse> {
  const url = `${BACKEND_URI}/contract`

  const headers = await getHeaders(idToken)
  const response = await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })

  if (response.status > 299 || !response.ok) {
    throw Error(`Error generating contract: ${response.status}`)
  }
  if (!response.ok) {
    throw new Error(`Error generating contract: ${response.statusText}`)
  }

  const dataResponse: ContractCreationResponse = await response.json()
  return dataResponse
}

export async function fillContractForm(
  request: ContractFillerRequest,
  idToken: string | undefined,
): Promise<ContractFillerResponse> {
  const url = `${BACKEND_URI}/fillcontractform`

  const headers = await getHeaders(idToken)
  const response = await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })

  if (response.status > 299 || !response.ok) {
    throw Error(`Error filling contract form fields: ${response.status}`)
  }
  if (!response.ok) {
    throw new Error(`ContractFillerResponse: ${response.statusText}`)
  }

  const dataResponse: ContractFillerResponse = await response.json()
  return dataResponse
}

export async function getSettings(
  idToken: string | undefined,
): Promise<ChatAppRequestOverrides> {
  const url = `${BACKEND_URI}/settings`
  const headers = await getHeaders(idToken)

  const response = await fetch(url, {
    method: 'GET',
    headers: { ...headers, 'Content-Type': 'application/json' },
  })

  return (await response.json()) as ChatAppRequestOverrides
}

export async function chatApi(
  request: ChatAppRequest,
  shouldStream: boolean,
  idToken: string | undefined,
): Promise<Response> {
  let url = `${BACKEND_URI}/chat`
  if (shouldStream) {
    url += '/stream'
  }
  const headers = await getHeaders(idToken)
  return await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
}

export async function getSpeechApi(text: string): Promise<string | null> {
  return await fetch('/speech', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      text,
    }),
  })
    .then((response) => {
      if (response.status == 200) {
        return response.blob()
      } else if (response.status == 400) {
        console.log('Speech synthesis is not enabled.')
        return null
      } else {
        console.error('Unable to get speech synthesis.')
        return null
      }
    })
    .then((blob) => (blob ? URL.createObjectURL(blob) : null))
}

export function getCitationFilePath(citation: string): string {
  // Regular expression to capture the number before "U.S.C." and the text after it
  const uscPattern = /(\d+)\s*U\.S\.C\.\s*([^\s]+)/

  // Check if the citation matches the pattern
  const match = citation.match(uscPattern)

  if (match) {
    const code = match[1] // Number before "U.S.C."
    const section = match[2] // Text after "U.S.C."
    // Return the specific URL format
    return `https://uscode.house.gov/view.xhtml?req=granuleid:USC-prelim-title${code}-section${section}&num=0&edition=prelim`
  }

  // If no match, return the default backend URI
  return `${BACKEND_URI}/content/${citation}`
}

export async function uploadFileApi(
  request: FormData,
  idToken: string,
): Promise<SimpleAPIResponse> {
  const response = await fetch('/upload', {
    method: 'POST',
    headers: await getHeaders(idToken),
    body: request,
  })

  if (!response.ok) {
    throw new Error(`Uploading files failed: ${response.statusText}`)
  }

  const dataResponse: SimpleAPIResponse = await response.json()
  return dataResponse
}

export async function deleteUploadedFileApi(
  filename: string,
  idToken: string,
): Promise<SimpleAPIResponse> {
  const headers = await getHeaders(idToken)
  const response = await fetch('/delete_uploaded', {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/json' },
    body: JSON.stringify({ filename }),
  })

  if (!response.ok) {
    throw new Error(`Deleting file failed: ${response.statusText}`)
  }

  const dataResponse: SimpleAPIResponse = await response.json()
  return dataResponse
}

export async function listUploadedFilesApi(idToken: string): Promise<string[]> {
  const response = await fetch(`/list_uploaded`, {
    method: 'GET',
    headers: await getHeaders(idToken),
  })

  if (!response.ok) {
    throw new Error(`Listing files failed: ${response.statusText}`)
  }

  const dataResponse: string[] = await response.json()
  return dataResponse
}

export async function getNotifications(idToken: string): Promise<Response> {
  const headers = await getHeaders(idToken)
  const url = `${BACKEND_URI}/notifications`
  const response = await fetch(url, {
    method: 'GET',
    headers: { ...headers, 'Content-Type': 'application/json' },
  })
  console.log(response)
  return await response.json()
}
