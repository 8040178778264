import { useEffect } from 'react'
import {
  PublicClientApplication,
  EventType,
  AccountInfo,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig, useLogin } from '../config/auth/authConfig'
import Layout from '../modules/Layout'
import { useAuthStore } from '../store/auth'
import { useUserStore } from '../store/user'

export const Shell = () => {
  const { checkAuthStatus, loggedIn } = useAuthStore()
  const { fetchUserDetails } = useUserStore()

  const msalInstance = new PublicClientApplication(msalConfig)

  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const account = event.payload as AccountInfo
      msalInstance.setActiveAccount(account)
      checkAuthStatus(msalInstance).then(() => {
        fetchUserDetails(msalInstance)
      })
    }
  })

  useEffect(() => {
    if (useLogin) {
      checkAuthStatus(msalInstance)
    }
  }, [checkAuthStatus])

  useEffect(() => {
    if (loggedIn) {
      fetchUserDetails(msalInstance)
    }
  }, [loggedIn])

  return (
    <MsalProvider instance={msalInstance}>
      <Layout />
    </MsalProvider>
  )
}
