import { IPublicClientApplication } from '@azure/msal-browser'
import { create } from 'zustand'
import { getUsername, getName, getId } from '../../config/auth/authConfig'

interface UserState {
  username: string | null
  name: string | null
  id: string | null
  fetchUserDetails: (instance: IPublicClientApplication) => Promise<void>
}

export const useUserStore = create<UserState>((set) => ({
  username: null,
  name: null,
  id: null,
  fetchUserDetails: async (instance) => {
    try {
      const username = await getUsername(instance)
      const name = await getName(instance)
      const id = await getId(instance)
      set({ username, name, id })
    } catch (error) {
      console.error('Failed to fetch user details:', error)
    }
  },
}))
